<template>
    <cms-block v-bind="$props" @block-selected="selectBlock" :style="blockStyle">
        <div class="grid-container">
            <cms-block-selector v-for="child in block.children" :key="child.id" @block-selected="selectBlock"
                                :block="child" is-child no-padding :block-selection="blockSelection" :editable="editable"/>
        </div>
    </cms-block>
</template>

<script>
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";

// cms-block-columns #hot-reload-debug
export default {
    name: `cms-block-columns`,
    components: {
        CmsBlockSelector: () => import(`@/components/editor/rendering/blocks/cms-block-selector.vue`),
        CmsBlock
    },
    extends: CmsBlock,
    computed: {
        blockStyle() {
            let style = {
                "--list-item-spacing": this.block.mediaProxy.spacing,
                "--list-item-columns": this.block.mediaProxy.columns
            }
            return style;
        }
    }
}
</script>

<style lang="scss" scoped>
.cms-block-columns {
    --list-item-columns: 0;
    --list-item-spacing: 10px;

    .grid-container {
        display: grid;
        grid-template-columns: repeat(var(--list-item-columns), 1fr);
        grid-gap: var(--list-item-spacing);
    }
}
</style>
